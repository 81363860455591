import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Showcase Gig さん主催の QA エンジニア向けイベントに弊社米山が登壇しました",
  "date": "2022-05-31T11:38:23.000Z",
  "slug": "entry/2022/05/31/203823",
  "tags": ["qa"],
  "hero": "./2022_05_31.png",
  "heroAlt": "QA イベント"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`はじめに`}</h1>
    <p>{`こんにちは。技術広報・エンジニアの平木です。最近使っている Emacs を Spacemacs から Doom Emacs に変更したところ気分も新たにテキスト活動が捗るようになりました。`}</p>
    <p>{`さて、2022/05/11 に行なわれた 「`}<a parentName="p" {...{
        "href": "https://showcase-gig.connpass.com/event/244393/"
      }}>{`QA Night 〜組織内で QA エンジニアがバリューを発揮し、キャリアアップするには〜`}</a>{`」 という QA エンジニア向けイベントで弊社 QA エンジニア米山がパネリストとして登壇しましたので、その様子をイベントレポートとしてお届けしたいと思います。`}</p>
    <h1>{`QA Night とは`}</h1>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/8b442ed28d35c282f5efbe9e2f5ef0f2/cc418/photo_00.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "77.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAIAAACZeshMAAAACXBIWXMAABYlAAAWJQFJUiTwAAAC/0lEQVQoz5WSX2hTdxTHzxWDrw724F5GQXG1uIZV9ElpbLukK661m1YZpfoqPokwdA4p4ovQ4aRNsAUHK2ywIXvYul38V8yqNml6m96QlEyahLZp7k3un9zce3Nz/+T+zmhSwQcR/fA5T4fvOfx+HMBcTsvlDNvG98SyLBi7NTb+4/idO8FQaDIUuttwKhicCoXeZnDibjabAwDweDzwzlAUUBQFADMzf8L4T/DbzO7p+577NMwl4Hnio+jy+dhSkFmeXIxPJtM/p/6bXk7eW2KnXvMew/4gyGF4sQLM6q6lly2qM4L4O6L0y/Sv318ZvXzp2+vXboSfzmdW80bVQkRCmo8ljRIsJwpV7LHxD4LSdoeQ765dHTk3fPHihbOnv57Y+o/bs08eI6LruoS4hDiEoEt4w44AQaExC+1S0cxm7M3C2kIsNfcstcgwDLMQiSRYNjI/z7LLCZbVda25m2Cp1ggXm2FXkNz1DYcv6S8zJsfXVLWsKIuxhWg0uhiLraRSqWSyWq02owSF2vZmRE2tFIp8WdNkVX0SDs/Q9D80PTs7S9P0w0eP4vElw9BM09RU22kchEMWanZ4O8xxpQSbymbXNzY4WVaLvCgKZZ4X0+lVnhdLRYXjjEKhtJp9IOo3q9hTxp2G8ze4mHaxjFvqiCpByUXBRYGgjFhBVLckOcRQSTsRZvY8ZXY8eEE9S3gMm4aDXujyf3joyM72z8B7iPpy8OPBU3uHvvmks/uDg15o76C8HdDeAZ92QJsX9rXC/jbYf4Da1wr/zv0Fhw8fOzkwFPD3H/f1dnV9cfrU8NkzI0NDw319g52d/uO+Xp/P7/P1dned9H/+VcDfH/APBAIDPd0nopEYbG7meZ7L59dFUVCUcrHIaVqlUMhLkmAYVV1XVbUsCMVarWrbZr1u1+v1euNeHMcBWZYlSao0UBRF0zTLsmzbtl5hmmazxfO8oiiyKAocp8qyZZqgKMra2lomk9F13XEc603YDeqW5SLmR0eTLS3po0cr8fj/stO0/XaCREoAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "img",
            "title": "img",
            "src": "/static/8b442ed28d35c282f5efbe9e2f5ef0f2/c1b63/photo_00.png",
            "srcSet": ["/static/8b442ed28d35c282f5efbe9e2f5ef0f2/5a46d/photo_00.png 300w", "/static/8b442ed28d35c282f5efbe9e2f5ef0f2/0a47e/photo_00.png 600w", "/static/8b442ed28d35c282f5efbe9e2f5ef0f2/c1b63/photo_00.png 1200w", "/static/8b442ed28d35c282f5efbe9e2f5ef0f2/d61c2/photo_00.png 1800w", "/static/8b442ed28d35c282f5efbe9e2f5ef0f2/cc418/photo_00.png 1992w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`こちらのイベントは `}<a parentName="p" {...{
        "href": "https://www.showcase-gig.com/",
        "title": "https://www.showcase-gig.com/"
      }}>{`Showcase Gig `}</a>{`さんが主催しているイベントである Geek Gig というエンジニアリングについての定期イベントがありまして、そのシリーズの 1 つとして運営されたものです。`}</p>
    <p>{`今回は Showcase Gig さんも弊社もテスト自動化ツールの `}<a parentName="p" {...{
        "href": "https://magic-pod.com/"
      }}>{`MagicPod`}</a>{` を使っているというご縁からお声がけいただきイベント開催の運びとなりました。`}</p>
    <h1>{`イベントについて`}</h1>
    <p>{`今回のイベントは、パネルディスカッションとして 2 社の QA についてそれぞれ語る形式になりました。`}</p>
    <p>{`モデレーターを Showcase Gig ソフトウェアエンジニアで VP of Technology である`}<a parentName="p" {...{
        "href": "https://twitter.com/_pochi"
      }}>{`菊池さん`}</a>{`が行ない、パネリストとして Showcase Gig QA エンジニア `}<a parentName="p" {...{
        "href": "https://twitter.com/y_6_5_"
      }}>{`横田さん`}</a>{`と、弊社 QA エンジニア米山とでざっくばらんにイベントが進行していきました。`}</p>
    <p>{`QA エンジニアの方だけではなく、モデレーターにエンジニアの方が入ったことによりバランスが良いパネルディスカッションになっているという感想でした。`}</p>
    <p>{`こちらのイベントは connpass で公開後、あれよあれよと 140 人まで申し込みがあり、注目度の高さが伺えました。`}</p>
    <p>{`当日のイベントの様子は下記からご覧いただけますので、ご興味のある方はぜひ。`}</p>
    <iframe width="560" height="315" src="https://www.youtube.com/embed/NGIpDGQKYFM" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    <h1>{`パネルディスカッション`}</h1>
    <p>{`パネルディスカッションは大まかに以下のようなセクションに分かれて実施されました。`}</p>
    <ul>
      <li parentName="ul">{`パネリスト・モデレーター自己紹介`}</li>
      <li parentName="ul">{`各社の開発/QA プロセスの理想と現実`}</li>
      <li parentName="ul">{`QA エンジニアがいなかったらどうなる?`}</li>
      <li parentName="ul">{`QA エンジニアのキャリア`}</li>
    </ul>
    <p>{`これらのセクションに合わせて、パネリストそれぞれの立場で回答をしていきましたが、途中で視聴されている方の質問などは、モデレーターの菊池さんがほぼリアルタイムで拾っていきながらの進行だったので、イベントの一体感が非常に感じられて面白かったです。`}</p>
    <h2>{`自己紹介`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/d1ac9060fb61fdbfa86dda5d1c32ade5/2adcb/photo_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "65.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAABYlAAAWJQFJUiTwAAACDklEQVQoz32STWsTQRjH8zG8eSr4DTz4ARQVDz2JJw/epQcPSq+FUgSv0lO1p2JFegkatQdTolTE5s0NNSHJhp0NybxtNruT3WfmGcmOLVXQH8Mwb//n+c8zU0rzL7GqCqEYowCgtWv6rNdnYwDIEQ3n/PDD+9pRtdFolAAbYnbSbnfr9ZM8z+0FjEFEvDA11tryu8qD1TuP7t/beblbAjDWZnEsgoD0+33GGOdcCJmmKed8MpkqpZzYBRr6fqVcbn87braaJQCwVmudSyk7nc6oQEpZrC+zXUz+F06MWbbgnEspkyTJ8xwR/6VBRBfRGONsp0pF3Z+96XRq/wNiIdVYXB4RXeYlURR5PzzBBWPM9/0gCKSU4/F4MpkQQhgX80TN5um5Iyc21qrZjA6HfhiGhBAAyAp08T7amPlM9k+bvXq19nav+nq7+/1oKS5sg7UGIOdchGEYBAGlVCm1WCzcCWtt79T7/PFV/3B3f3Pt5tWV51tPlrXU+rdtRKSUNgtarRZjzNnTWltr3+zvvdhaO7h74+GVy9dWLj3bWP9D7PTOJwC4tOcfo/61drC98XT11vrt6zubj48/VTJYaK1LSZLEcRxFEec8LYjjOMsyxth8Pne7cRynqTLWokVX9RwigLzEOfc8r9vt9nq9wWBACJFSRlEUhqEQIiqglPqjESGEUkoZm9IppUwp9Qv1o8pq0skHEAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "img",
            "title": "img",
            "src": "/static/d1ac9060fb61fdbfa86dda5d1c32ade5/c1b63/photo_01.png",
            "srcSet": ["/static/d1ac9060fb61fdbfa86dda5d1c32ade5/5a46d/photo_01.png 300w", "/static/d1ac9060fb61fdbfa86dda5d1c32ade5/0a47e/photo_01.png 600w", "/static/d1ac9060fb61fdbfa86dda5d1c32ade5/c1b63/photo_01.png 1200w", "/static/d1ac9060fb61fdbfa86dda5d1c32ade5/d61c2/photo_01.png 1800w", "/static/d1ac9060fb61fdbfa86dda5d1c32ade5/2adcb/photo_01.png 1984w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/c9ef36d3564664f8091ab94c0fe1adaa/c9d77/photo_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "67%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAABYlAAAWJQFJUiTwAAACCUlEQVQoz4WSO4gUQRCGJ1AEMTYzMzRUE+FCEcVIxEBQEwMTA8FAERU9OEEwucCLDjEwuEAwMfUVbOyxCO7uuO7OTM+ruqdnZ2an+jUtOy3HXSB+QdF0Uf1X1d9e2+0A//LLJ74/0dp0B7F2FU2P1tpaCxA9ffzgxfNHm1tbnulY3RS/e4QQqkdrrZSSUiKiq7HWGmOstYPBt4vn1+7duHT/2YandWcttm1TFNz3/TiO5/M5ISTLsqJnuVxa18CqEcuKYjT6+WO460+nntbGWqzrxWTiA0BVVQDAOV8sFnua/+JvMYplEARhGCJi13WueWOM7EFEdyOEkFKuohBaa889b4wBgNFolOc5pXQ2m4VhyDlPkoRSGkVRmqacFwUvTd+8G8TNXDOWzucr5SAItNbGGKeslELEtm37XeqP73febL7aWF9/u72tlPL2BgOA8XgcxzEA+L5PCCl74jiOoijPcyHV9csXjh89cuzQ4XNnTkspDxRPp1MAIIQMh0Nnnsu6PSOKW1evXDt7au3kibu3bxpjVsXuG8h9IGLTNFVVOYecw1KIwddP714+ef3wzvfPH7TS3n/92MdKv6mrkmVJNNFKemVZZlmWJEkcx4yxoigopXVdE0Kc25xzSiljDBEFopRSaaNNKyV6jDFnDCHEbavuoZRWVeXOjLE0TV2WUUopANC2bf8AyRLEszgjTacAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "img",
            "title": "img",
            "src": "/static/c9ef36d3564664f8091ab94c0fe1adaa/c1b63/photo_02.png",
            "srcSet": ["/static/c9ef36d3564664f8091ab94c0fe1adaa/5a46d/photo_02.png 300w", "/static/c9ef36d3564664f8091ab94c0fe1adaa/0a47e/photo_02.png 600w", "/static/c9ef36d3564664f8091ab94c0fe1adaa/c1b63/photo_02.png 1200w", "/static/c9ef36d3564664f8091ab94c0fe1adaa/d61c2/photo_02.png 1800w", "/static/c9ef36d3564664f8091ab94c0fe1adaa/c9d77/photo_02.png 1964w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`横田さんも米山も現職に至るまでの経歴がかなり似ていました。第三者検証会社で様々な業態の会社を顧客として、様々な経験を積み重ねてから、事業会社での QA エンジニアとして特定のプロダクトの品質を高める仕事をしていらっしゃるという点です。`}</p>
    <p>{`第三者検証会社で様々なニーズに対応したことが、プロダクトへの貢献に活きていそうだと感じました。`}</p>
    <h2>{`各社の開発/QA プロセスの理想と現実`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/7ea21df12ca33382e9e1181cc35758cc/82e86/photo_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAABYlAAAWJQFJUiTwAAACcklEQVQoz5XSPU8iQRgH8PkARDtt6KhtLExUvMICjUqsBDXHbQgWBCTGaDSBGBILGmNwkZd4kUswJBdtbO6KM3Z+Ajs9dV33cHdnX4CFZRl2l50Ly3m5ay65XzHNPP88T54Z0O5+lRtffjDyK1vhOI5hmOfnZ8YmiqJpmt03pmlijB+fngrk0cdM5tvVFTAtudEUHh+pl5cXjuNomqYoiuM4lmUlSdJ13bIsbOt2uxjjq+vrGPE+Hvrw+fwcGIaB31g2/E8IoUrltSpJEPKg00GiKB4dkaVSSdM0VVUNG8b44eEhmUze3t5ijA3D6A+PUEfXddOeotcZQuhwONxu9+/+/fDFxQUA4Pj4uB/u3yqKcn939/3+vl6vA4QQRVEul2t6ejoaja6srCwvL/t8vtXV1bW1NafTSRBEKBRaWlry+XypVAoh5PV6h4eHE4lEL0zT9MDAwOjo6P7+/vb29u7u7vr6eiqVIggCADA3N3d4eLixsREOh+Px+OXl5c7Ozvz8/NnZGeh0OoIgLCwsRCKRRCIRjUa3traCweDBwcHm5qbH4/H7/SRJJpPJWCwWCAQikQhBEE6nc29vD9g7QIuLi2NjY7Ozs16vd2ZmxmMbHx8fGRmZmJiYnJx0u91TU1PvbENDQ4ODgzc3N8A0TcMwyuVyPp8/OTkpFov5fD6XyxUKBZIks9lsJpMh/5BOp3O53Onpp2q1+tc7/w/dsrpAURSO4yqVCk3TgiCIogghrNVq/e8py7IoijzPQwibzWar1dJavUNt1RBCoNFoMAzDsixvk2VZ03oF9XpdVdVeoaYpigIh5HlekiT5l2q73f4JWdo9oUFtl1kAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "img",
            "title": "img",
            "src": "/static/7ea21df12ca33382e9e1181cc35758cc/c1b63/photo_03.png",
            "srcSet": ["/static/7ea21df12ca33382e9e1181cc35758cc/5a46d/photo_03.png 300w", "/static/7ea21df12ca33382e9e1181cc35758cc/0a47e/photo_03.png 600w", "/static/7ea21df12ca33382e9e1181cc35758cc/c1b63/photo_03.png 1200w", "/static/7ea21df12ca33382e9e1181cc35758cc/d61c2/photo_03.png 1800w", "/static/7ea21df12ca33382e9e1181cc35758cc/82e86/photo_03.png 1978w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/9d53961ae3711f38502809827024ff14/cad61/photo_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAABYlAAAWJQFJUiTwAAACbklEQVQoz42SvUsjURTFn6XYWASiNpKMsjaCpduJxGpBzfbbWIigpQT/gmUtFF3tdQlrilVxBG200bhfbli/wMJRMZnJzDB5896bj52ZvHkzbzERsfRX3ss5cM89wIu+EucvQv+IhWVZ1jRN17Vazbi7u5MkyXXdOI6jBmEYcs6vr/8sL3xc+bxw8v0EsLhi2dAwagih29vbcrlcqVSq1erl5eX5+bllWZzzOI4554xFnPPllU/v3r758D7z5dsWiKLH0WtoWtzcSKXfP3/9OFE0FQSBHwT+8w5j/PDwIMsyIYRSahgGhBBjbNs2IRal9NkriiIQhuHe3t7U1JQoihMTE4uLi89GnHNCiGmasiw3L4IQxnEcBIHneXVKged5FxcXhULh6upqY2OjWCweHx+Lori/v7+5uZnP59fW1ra3tzKZTDotzMzMLC0t5XK5g4MDzjmglIqiODw8XCgUdnZ2dnd3h4aGOjo6BEHo6upKJBJtbW3ZbHZwcLClpWVgYKC1tRUAMD8//ygOw3B9fT2dTk9PT6dSqdHR0ZGRkWQy2d3dnUwmOzs729vbx8fHe3t7AQD9/f2gwZPY932EkCRJ5Qb39/djY2N9fX2CIKRSqZ6enkQiMTk5ubq6Ojc3l8/nc7nc7Ozs2dnZo5gx1kyIUsoYo5RKklQqlQ4PD4vF4tHR0enpqaIoL1Nswhh77Z+bDaOUep7vea7n44hFAGNsGIaqqrqu4wamaWKMVVW1LAtjjBCq1WqEkHq9Tp8IgsCmlAIIYbMViqJUq1WEkOu6juNACB3HsW3bdV0Ioa7rmqYhhEzThNDE2AqC4D8FOFytih6AQwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "img",
            "title": "img",
            "src": "/static/9d53961ae3711f38502809827024ff14/c1b63/photo_04.png",
            "srcSet": ["/static/9d53961ae3711f38502809827024ff14/5a46d/photo_04.png 300w", "/static/9d53961ae3711f38502809827024ff14/0a47e/photo_04.png 600w", "/static/9d53961ae3711f38502809827024ff14/c1b63/photo_04.png 1200w", "/static/9d53961ae3711f38502809827024ff14/d61c2/photo_04.png 1800w", "/static/9d53961ae3711f38502809827024ff14/cad61/photo_04.png 1988w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`QA を含めた開発体制については、両社で違いがもちろんありますが大きい違いでいうと、現在のメドレーではプロダクト開発チームの一員として QA エンジニアが所属しています。他方、Showcase Gig さんでは複数の開発チームを横断して QA チームが存在しているという部分でしょうか。`}</p>
    <p>{`メドレーの現在の開発体制では、チーム内で開発メンバーと密接にやり取りをしながら品質を高めていくというプロセスが取られており、別の開発チームのヘルプなども、もちろんありますがアドバイザー的な感じで、実際にはそのチーム内で品質を高めるように動いています。`}</p>
    <p>{`一方で Showcase Gig さんでは横断チームとして各プロダクトを俯瞰して見れるような体制にしている印象でした。それぞれの開発チームとは要所でシンクアップすることにより会社全体での QA プロセスを統一しながら各チームに展開できるのが良さそうだなと感じました。`}</p>
    <h3>{`理想と現実について`}</h3>
    <p>{`そんな両社ですが、共通する部分として事業領域の難しさがありました。 メドレーは医療領域という難しさ、Showcase Gig さんではオンラインとオフラインの両立をしなければならない部分が特に難しいとのこと。`}</p>
    <p>{`そこから話は QA の理想と現実に移っていきます。メドレーではプロダクトのより上流部分である仕様策定部分からバグを潰せるようにしていくのを目指しつつも、ガチガチな方法や体制にならないように日々 QA を行なっています。また E2E テストの失敗が非常事態だとメンバーが焦るくらいに成功率を高めていきたいという理想がありますが、どうしてもマニュアルでのテストが必要になってくるプロダクトなので、E2E テストだけに頼らないように試行錯誤しています。`}</p>
    <p>{`Showcase Gig さんでは品質は担保しつつ、今以上にリリース頻度を上げるためにどうしたら良いかという点を開発チームと協業で改善しようとされているそうです。MagicPod を使った E2E テストだけでリリースまでできるようにするのが理想ではありますが、その前にプロセスの制定など前段階の準備を着々としているそうです。`}</p>
    <h2>{`QA エンジニアがいなかったらどうなる?`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/73f4a58dc69fc1dac9a59ef20a9ce41d/5b503/photo_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAABYlAAAWJQFJUiTwAAADGklEQVQoz2O4dOHM+9f3Du7fHhsbk56Z0dndXlpZFpWQmJqRmZIWd/Hyrs+fvr57++bD+/fv37378P7D58+fPn768PXb9wMHDjCcPHbg/58Xa9cslJKXdfH0aKwpzk8K8/f3MbGzd/TwvHnz5v///z9/+/bs5ct3Hz+9efPi2IYZZ7bO/P//55at2xhOHj/47cP9OXOnKqqpZhXktxSlhTkYJIT7VXRMLGnsuHn79v/v399euPDq3Lk3Fy7tXDi5MtSx0Mv8wKr+HTt3Mhw/uv/t82tNrfXSiorFNbWnVk/cVBtSVVrQs3RLYX3bjZs3///58/np07cPH75++HD93KmF7nZpdma7ls/au28fw7Ej+x7dPZudl62srqFvYjO7Ivbs1CRjfR2PsISY3OKrV6/+////1Zs37z9+/PLt29ljR0t83Cv9nGf3tE7s62GYPn1qUKCvsYmJsLAIAwNTTJx3d3cJnxhXRFLY3LXzq5vL5i+feeX2malz+vKKk5ztbUw01c00lHU0VEKDAhnKysoYGBhYWFiYGJkYGBiKa/IPHz5aXV21YP68FcuWmWhpWZuYrF27pqerOyIxmAGkhIGJCURZW1szlJeXMzAwsLKxMoI1u3jbVpZXHD16ePbcud6ONrVhPiXeLgkRITt37Z6zeCojEwMTIxMrCwsDA4ONjQ1UMxsbGwszSKimpfzSxYth4aEtba1LO+vK/NwKvJwXz5za09PrH+bBxMIIt9nW1hbhbEYGkIS9m1V2Zs7sWbMmTpzUmZvaFhZS7uNdkJa6c8fuJavnMjAxCPALCAoKsrGxgZzd1NTEzs4uKSkpLibOy8fT3Fuz78iu2OTIleuX7lg3LzfIo7Uw48Xbh80d9bYeppJSEgb6Bnp6eoqKit7e3gwPHz48ceLEyZMnT5w4ceHCheu3r964e/XR8/sXr527+/DG3Qc37j+98/DZ/ftP7tx/cvfevXu3wODmzZtPnjxh+PLly5s3bz7AwOdPX37//PPr5+/v377//PHr968/v3/+/vTh04f3H9++fffp06cvMPDnzx8AOeyZjR67oYoAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "img",
            "title": "img",
            "src": "/static/73f4a58dc69fc1dac9a59ef20a9ce41d/c1b63/photo_06.png",
            "srcSet": ["/static/73f4a58dc69fc1dac9a59ef20a9ce41d/5a46d/photo_06.png 300w", "/static/73f4a58dc69fc1dac9a59ef20a9ce41d/0a47e/photo_06.png 600w", "/static/73f4a58dc69fc1dac9a59ef20a9ce41d/c1b63/photo_06.png 1200w", "/static/73f4a58dc69fc1dac9a59ef20a9ce41d/d61c2/photo_06.png 1800w", "/static/73f4a58dc69fc1dac9a59ef20a9ce41d/5b503/photo_06.png 1976w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`QA エンジニアのイベントで中々出てこないような設問もあり大変面白かったのですが、それがこの「QA エンジニアがいなかったらどうなる?」という設問でした。`}</p>
    <p>{`興味深かったのはお二人とも同じような返答だったことです。両社とも「リリースは通常通り行なわれるだろうが、リリース後の不具合対応などが増えるかもしれない」ということでした。またこれも共通して「QA エンジニアがいなくても品質保証がされることが理想」というお話でした。`}</p>
    <p>{`実際に QA エンジニアはいなくてもきちんと高い品質のプロダクトを世に出していける仕組みや体制が理想というのは、関係者に品質についての意識が根差していけるようにするという意気込みのように聞こえ感銘を受けました。`}</p>
    <h2>{`QA エンジニアのキャリア`}</h2>
    <p>{`また普段お二人はどのような意識配分で日々の仕事をされているかというのを円グラフで表わす試みが良かったです。
普段どのような事に時間や意識を割いているかというのが分かると自分の仕事の比率と比べてみちゃいますね。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/756a58c5db32753eca6590e6c8e12841/f349c/photo_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "64.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAABYlAAAWJQFJUiTwAAACF0lEQVQoz32Sv2/TQBTHvfJnsMKIyobE0BUWJITYOzDAvwB/ABs7SyXEjwqqwkCVIcpAWkepkgYnRHESN43j2j377Duffa7vzg/VV6goiM9wOj297/f79PSMTG7TbJQkeRSFaZquVivXdT3PI4RgjAkhjDGMsZRSCAEA6NTd2nq/ubnZPTgwBPTCaDGZTG3bdl230+mYZsc09wPfm8+nc2d+EviWZakaANjZeXf39s3H99dfv/1k6NLfRAyWp5Kd4DOaAUBVAwD9w/6X7a3dzx/H9sxQSgKAUqq6QEmlBke8MeCtPl3sjdx9CzseVFfdpZQXydq1fmDqi0Y/b42KtkWX3YnXHS/3rDTAAMA5j+NYSllVlRDicmxCCEIo52fmhDct3hzyzijze1Ov+2NljoLhXCmV53mSJDrpMhkAhDjfKD9T7TFvfs/bE/mhtWh/bePBbGkO0dCp6k4ppfr1+WNsTXdWNPqsv4CNl+/XHj2NBjOvMwonS90mhPi3WDucxLJxmH0bi7WNF8aN9WfPX+GezQn7vaf/JVeVQhTeNJ1rdx4at+5df/Dk+GgFAGVZxnEchuFVsdZrV13BNNtumbtmL0qoqpSoybIsiiLOeVEU52JKKSEkrEnTlBCSJAmlaYgQVErJMs9YHMeMMaWUdtenWpal4fu+bduO4xzXaAtK6SlCSW3EGAuCwPM813V1BkIIY1wUxU9Uc8VCIRh4egAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "img",
            "title": "img",
            "src": "/static/756a58c5db32753eca6590e6c8e12841/c1b63/photo_07.png",
            "srcSet": ["/static/756a58c5db32753eca6590e6c8e12841/5a46d/photo_07.png 300w", "/static/756a58c5db32753eca6590e6c8e12841/0a47e/photo_07.png 600w", "/static/756a58c5db32753eca6590e6c8e12841/c1b63/photo_07.png 1200w", "/static/756a58c5db32753eca6590e6c8e12841/d61c2/photo_07.png 1800w", "/static/756a58c5db32753eca6590e6c8e12841/f349c/photo_07.png 2002w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`ここから、皆さんが気になる QA エンジニアのキャリアについてお二人が話をされました。`}</p>
    <p>{`米山は 15 年の QA 経験を持っていますが、その中でアジャイルやテスト自動化などのトレンドには「自分でもできるかな」と思いながら導入をしていったとのことでした。そうしながら、自分の中のプライオリティはあくまでも事業・プロダクトという部分だったので、ここにコミットができる立ち位置での仕事をずっと続けてきたと言います。`}</p>
    <p>{`先のキャリアとしては、ポジションに捕われず事業価値を高めるように動いていき、どのような状況のチームでも品質を高めるための推進ができるようにしていきたいということでした。`}</p>
    <p>{`一方の横田さんも今までの経験を踏まえ QA のポジションを高めていけるような動きをしていきたいということでした。テストなどに興味を持つ人の裾野を広げていきたいという意欲を感じました。個人としては UI/UX なども含めて品質を高めるということができればとのことでした。`}</p>
    <p>{`お二人とも、「自分のキャリア」に留まらず周囲の人間にも、良い影響を与えていきたいということをおっしゃっているのが非常に印象的でした。`}</p>
    <h1>{`最後に`}</h1>
    <p>{`紹介した以外にも現場に即した QA についてや、品質についての考え方の一端が分かるようなイベントでした。自分はエンジニアという立場で視聴していましたが、そうした人間にも大変に示唆に富むお話が多く、あっという間に 1 時間が経過しました。QA エンジニアの方はもちろん、その他プロダクトを作るということに関わる方はぜひ、アーカイブを視聴してみてください!`}</p>
    <iframe width="560" height="315" src="https://www.youtube.com/embed/NGIpDGQKYFM" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      